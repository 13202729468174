import React from "react"
import { Link } from "gatsby"

import Layout from "../layouts/layout"
import BestSkill from "../components/best-skill"
import Skill from "../components/skill"
import POSWindow from "../components/pos-window"
import SEO from "../components/seo"
import CustomerType from "../components/customer-type"

import France from "../images/france.svg"
import ReactLogo from "../images/technologies/react-logo.svg"
import AngularLogo from "../images/technologies/angular-logo.svg"
import NodeJsLogo from "../images/technologies/nodejs-logo.svg"
import SymfonyLogo from "../images/technologies/symfony-logo.svg"
import LaravelLogo from "../images/technologies/laravel-logo.svg"
import PythonLogo from "../images/technologies/python-logo.svg"
import WordpressLogo from "../images/technologies/wordpress-logo.svg"
import AdobeXDLogo from "../images/technologies/adobexd-logo.svg"

import indieImage from "../images/indie.jpg"
import startupImage from "../images/startup.jpg"
import agencyImage from "../images/agency.jpg"
import enterpriseImage from "../images/enterprise.jpg"


import indexStyles from "./index.module.css"

const IndexPage = () => (
	<Layout>
		<SEO 
			title="Développeur logiciel à la demande." 
			description="J'intégère votre équipe de développement ou je réalise un de vos projets en autonomie et en marque blanche." 
			/>
		<div className={indexStyles.hero}>
			<div className={indexStyles.heroLeft}>
				<h1>Développeur logiciel<br />à la demande.</h1>
				<p>Vous avez besoin d'une ressource en développement logiciel pour répondre à une demande ponctuelle.</p>
				<p><b>J'intégère votre équipe de développement ou je réalise un de vos projets en autonomie et en marque blanche.</b></p>
				<ul>
					<li>Freelance</li>
					<li>A distance / Hauts-de-France</li>
					<li>Valenciennes, France</li>
				</ul>
				<Link
					to="/contact"
					className={`
						contact-button
						${indexStyles.indexCta}
					`}>
					Nouveau projet
				</Link>
				<div className={indexStyles.technologiesLogos}>
					<ReactLogo title="React" />
					<AngularLogo title="Angular" />
					<NodeJsLogo title="NodeJs" />
					<SymfonyLogo title="Symfony" />
					<LaravelLogo title="Laravel" />
					<PythonLogo title="Python" />
					<WordpressLogo title="Wordpress" />
					<AdobeXDLogo title="Adobe XD" />
				</div>
			</div>
			<div className={indexStyles.heroRight}>
				<POSWindow
					className={indexStyles.windowProfile}
					width={196}
					height={196}
					title="remi-parent.jpg"
					/>
				<POSWindow
					className={indexStyles.windowDesktop}
					width={348}
					height={265}
					title="remote-desktop_20200307.jpg"
					/>
				<POSWindow
					className={indexStyles.windowMap}
					width={234}
					height={228}
					title="https://maps.remiparent.com"
					>
					<France className={indexStyles.franceMap} />
					<div className={indexStyles.franceMapPointBleep}></div>
					<div className={indexStyles.franceMapPoint}></div>
				</POSWindow>
			</div>
		</div>
		<div className={indexStyles.indexSection}>
			<h2>La façon dont nous pouvons travailler ensemble</h2>
			<div className={indexStyles.customers}>
				<CustomerType 
					title="Pour les Indépendant(e)s"
					description="Lancez votre activité rapidement avec des outils adaptés." 
					image={indieImage}
					/>
				<CustomerType 
					title="Pour les Startups"
					description="Travaillons ensemble sur les itérations de votre produit." 
					image={startupImage}
					/>
				<CustomerType 
					title="Pour les Agences & ESN"
					description="Renforcez votre équipe de développement de manière flexible." 
					image={agencyImage}
					backgroundAlign="left center"
					/>
				<CustomerType 
					title="Pour les Entreprises"
					description="Mettons au point des outils pour augmenter votre performance." 
					image={enterpriseImage}
					backgroundAlign="left center"
					/>
			</div>
		</div>
		<div className={indexStyles.indexSection}>
			<h2>Ce sur quoi je peux vous aider</h2>
			<div className={indexStyles.skills}>
				<BestSkill 
					title="Software as a Service (SaaS)"
					subtitle="Fullstack"
					>
					Réalisation technique d'une application web de A à Z (full-stack).
				</BestSkill>
				<div className={indexStyles.otherSkills}>
					<Skill 
						double 
						title="Single-Page Application (SPA)"
						subtitle="Frontend"
						>
						Une application web dynamique sur laquelle vos utilisateurs pourront effectuer de nombreuses actions (Exemple: Tableau de bord, Interface d'administration).
					</Skill>
					<Skill 
						title="API REST"
						subtitle="Backend"
						>
						Une interface technique pour permettre à vos différentes applications de communiquer entre elles.
					</Skill>
					<Skill title="Scripting">
						Création d'outils internes pour améliorer la productivité de votre entreprise.
					</Skill>
					<Skill title="Conception">
						Définition des fonctionnalités et de l'interface de votre produit ou de votre MVP.
					</Skill>
					<Skill title="Vitrine / E-Commerce">
						Mise en avant de vos services et de vos produits avec une approche orientée conversion.
					</Skill>
				</div>
			</div>
		</div>
	</Layout>
);

export default IndexPage