import React from 'react'

import skillStyles from './skill.module.css'

const Skill = ({title, subtitle, children, double}) => (
    <div className={`${skillStyles.skill} ${double ? skillStyles.double : null}`}>
        <h3>            
            {
                subtitle &&
                <span className={skillStyles.subtitle}>{subtitle}</span>
            }
            {title}
        </h3>
        <p>{children}</p>
    </div>
)

export default Skill