import React from "react"
import Draggable from 'react-draggable';

import pOSWindowStyles from './pos-window.module.css'

const POSWindow = props => (
    <Draggable handle={'.'+pOSWindowStyles.pOSWindowTitle}>
        <div 
            className={`
                ${pOSWindowStyles.pOSWindow}
                ${props.className}
            `}
            style={{
                top: props.top,
                right: props.right,
                bottom: props.bottom,
                left: props.left,
            }}
            >
            <div className={pOSWindowStyles.pOSWindowTitle}>
                <b>{props.title}</b>
            </div>
            <div 
                className={pOSWindowStyles.pOSWindowContent}
                style={{
                    width: props.width,
                    height: props.height,
                }}
                >
                {props.children}
            </div>
        </div>
    </Draggable>
)

export default POSWindow