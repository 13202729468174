import React from 'react'

import bestSkillStyles from './best-skill.module.css'

const BestSkill = ({title, subtitle, children}) => (
    <div className={bestSkillStyles.bestSkill}>
        <h3>
            {
                subtitle &&
                <span className={bestSkillStyles.subtitle}>{subtitle}</span>
            }
            {title}
        </h3>
        <p>{children}</p>
        <ul className={bestSkillStyles.list}>
            <li>Intégration</li>
            <li>Développement Front-end</li>
            <li>Développement Back-end</li>
            <li>Modélisation des données</li>
            <li>Spécifications fonctionnelles & techniques</li>
        </ul>
    </div>
)

export default BestSkill