import React from 'react'
import { Link } from "gatsby"

import customerTypeStyles from './customer-type.module.css'

const CustomerType = ({title, description, image, backgroundAlign}) => (
    <div 
        className={customerTypeStyles.customerType}
        style={{
            backgroundImage: `url(${image})`,
            backgroundPosition: backgroundAlign ? backgroundAlign : null,
        }}
        >
        <div className={customerTypeStyles.overlay}></div>
        <div className={customerTypeStyles.content}>
            <h3>
                {title}
            </h3>
            <p>{description}</p>
            {/* <Link
                to="/"
                className={customerTypeStyles.moreInfoButton}
                >
                En savoir plus
			</Link> */}
        </div>
    </div>
)

export default CustomerType